import { useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_ClipLoader } from '../../components/loading/ClipLoader'

export function Loader() {
    const { show_loading } = useContext(GeneralContext)

    return (
        show_loading && (
            <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                <SL_ClipLoader loading={true} />
            </div>
        )
    )
}
