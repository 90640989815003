import { DateTime } from "luxon"
import { displayMessage } from "../../../helpers/messages"
import { List, Tooltip } from "antd"

export const report_risk_grid_columns = (handleOnDownload) => [
    {
        field: 'sequence',
        headerName: displayMessage('RECOMMENDATION_NUMBER'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.sequence) return row.sequence
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'Risk',
        headerName: displayMessage('RISK'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Risk) return row.Risk.description
            return displayMessage('EMPTY_01')
        },
        renderCell: (params) => {
            return (
                <Tooltip placement='bottom' title={params.formattedValue} overlayStyle={{ maxWidth: 500 }} overlayInnerStyle={{backgroundColor: 'white', color: 'black', textAlign: 'justify'}}>
                    <strong style={{ width: '100%', fontWeight: 'normal' }}>
                        {params.formattedValue?.length > 0 ? params.formattedValue : displayMessage('EMPTY_01')}
                    </strong>
                </Tooltip>
            )
        }
    },
    {
        field: 'Recommendation',
        headerName: displayMessage('RECOMMENDATION'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Risk) return row.Risk.Recommendation.title
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'priority_level',
        headerName: displayMessage('PRIORITY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.PriorityLevel) return row.PriorityLevel.name
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'affected_pillar',
        headerName: displayMessage('AFFECTED_PILLAR'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.AffectedPillar) return row.AffectedPillar.title
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'frequency',
        headerName: displayMessage('FREQUENCY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Frequency) return row.Frequency.description
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'control_mesure',
        headerName: displayMessage('CONTROL_MESURE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.ControlMesure) return row.ControlMesure.description
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'detection',
        headerName: displayMessage('DETECTION'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Detection) return row.Detection.description
            return displayMessage('EMPTY_01')
        }
    },{
        field: 'severity',
        headerName: displayMessage('SEVERITY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Severity) return row.Severity.description
            return displayMessage('EMPTY_01')
        }
    }, {
        field: 'coverage_scale',
        headerName: displayMessage('COVERAGE_SCALE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.CoverageScale) return row.CoverageScale.description
            return displayMessage('EMPTY_01')
        }
    },
    {
        field: 'attachments',
        headerName: displayMessage('ATTACHMENTS'),
        type: 'string',
        width: 450,
        renderCell: (params) => {
            const row = params.api.getRow(params.id);
            const attachment = row?.Attachment;
    
            if (attachment?.file) {
                return (
                    <Tooltip
                        title={
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        }
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                    >
                        <div className="flex flex-row">
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        </div>
                    </Tooltip>
                );
            }
            return displayMessage('EMPTY_01');
        }
    },



// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\


    // {
    //     field: 'Normatives',
    //     headerName: displayMessage('NORMATIVES'),
    //     type: 'string',
    //     width: 300,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         if (row?.Risk?.Normatives) return row.Risk.Normatives.map(normative => normative.description)
    //         return displayMessage('EMPTY_01')
    //     },
    //     renderCell: (params) => {
    //         const renderList = () => {
    //             return (
    //                 <List
    //                 size='small'
    //                 className="rounded p-0 m-0"
    //                 itemLayout="horizontal"
    //                 dataSource={params.row?.Risk?.Normatives}
    //                 renderItem={item => (
    //                     <List.Item className="p-2">
    //                         <p>{item?.description}</p>
    //                     </List.Item>
    //                 )}
    //             />
    //             )
    //         }
    //         return (
    //             params.formattedValue?.length > 0 && params.formattedValue != displayMessage('EMPTY_01')
    //                 ?
    //                 <Tooltip placement='bottom' title={renderList()} overlayStyle={{ maxWidth: 500 }} overlayInnerStyle={{backgroundColor: 'white', color: 'black', textAlign: 'justify'}}>
    //                     <strong style={{ width: '100%', fontWeight: 'normal' }}>
    //                         {params.formattedValue?.length > 0 ? params.formattedValue : displayMessage('EMPTY_01')}
    //                     </strong>
    //                 </Tooltip>
    //             :
    //             displayMessage('EMPTY_01')
    //         ) 
    //     }
    // },
    
   
    
    
    
   
   
    // {
    //     field: 'WorkSector',
    //     headerName: displayMessage('WORK_SECTOR'),
    //     type: 'string',
    //     width: 250,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const work_sector = row?.WorkSector
    //         if (work_sector) return work_sector?.name
    //         return displayMessage('EMPTY_01')
    //     }
    // },
    // {
    //     field: 'client_users',
    //     headerName: displayMessage('TECHNICAL_MANAGER'),
    //     type: 'string',
    //     width: 250,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const client_users = row?.ReportRiskClientUsers.map(rc => `${rc?.ClientUser?.first_name} ${rc?.ClientUser?.last_name}`).join(', ')
    //         if (client_users) return client_users
    //         return displayMessage('EMPTY_01')
    //     }
    // },
    // {
    //     field: 'location',
    //     headerName: displayMessage('LOCATION'),
    //     type: 'string',
    //     width: 250,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const location = row.location
    //         if (location) return location
    //         return displayMessage('EMPTY_01')
    //     }
    // },
    // {
    //     field: 'observations',
    //     headerName: displayMessage('OBSERVATIONS'),
    //     type: 'string',
    //     width: 250,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const observations = row?.observations
    //         if (observations) return observations
    //         return displayMessage('EMPTY_01')
    //     }
    // },
    // {
    //     field: 'evidence_description',
    //     headerName: displayMessage('EVIDENCE_DESCRIPTION'),
    //     type: 'string',
    //     width: 250,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const image_description = row?.image_description
    //         if (image_description) return image_description
    //         return displayMessage('EMPTY_01')
    //     }
    // },
   
    

]