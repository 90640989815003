import { Drawer } from 'antd'
import { useState } from 'react'
import { displayMessage } from '../../../helpers/messages'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import { Menu } from './Menu'

export function DrawerMenu() {
    const [menuDrawerVisible, setMenuDrawerVisible] = useState(false)

    const onCloseDrawer = () => setMenuDrawerVisible(false)
    const onOpenDrawer = () => setMenuDrawerVisible(true)

    return (
        <div className="flex items-center flex-1">
            <MenuUnfoldOutlined className=" text-2xl text-primary-700 cursor-pointer" onClick={onOpenDrawer} />

            <Drawer
                title={
                    <div className="flex items-center mb-0">
                        <span className="ml-3 text-lg font-semibold">{displayMessage('MENU')}</span>
                    </div>
                }
                onClose={onCloseDrawer}
                placement="left"
                bodyStyle={{ padding: 10 }}
                open={menuDrawerVisible}
            >
                <Menu collapsed={false} isSideMenu={false} />
            </Drawer>
        </div>
    )
}
