import { useContext } from 'react'
import { Modal } from 'antd'

import { Chat } from './Chat'
import { displayMessage } from '../../../../../helpers/messages'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import './chat.css'

export default function ModalChat({ isFollowupFinished, onCloseChat, followupReportRiskId, recommendation }) {
    const { mobile_mode, windowHeight } = useContext(GeneralContext)
    return (
        <Modal
            title={<span className="text-primary-900">{displayMessage('DIVERGENCE')}</span>}
            centered={true}
            open={true}
            onCancel={onCloseChat}
            footer={null}
            bodyStyle={{
                top: 20,
                height: mobile_mode ? '600px' : 0.85 * windowHeight,
            }}
            className={`w-full modal-chat`}
        >
            <Chat isFollowupFinished={isFollowupFinished} recommendation={recommendation} followupReportRiskId={followupReportRiskId} />
        </Modal>
    )
}
