import { ConfigProvider, Input } from 'antd'
import { twMerge } from 'tailwind-merge'
import { colors } from '../../services/styles'

export function SL_Input({ className = '', textarea = false, maxLength = 255, ...rest }) {
    const classes = twMerge(`
        ${className ?? ''}
    `)

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: colors.primary[900],
                    colorTextPlaceholder: colors['sl-gray'][400],
                },
            }}
        >
            {textarea ? <Input.TextArea maxLength={maxLength} {...rest} className={classes} /> : <Input maxLength={maxLength} {...rest} className={classes} />}
        </ConfigProvider>
    )
}
