import { FileSearchOutlined, LineChartOutlined, LoginOutlined, PicCenterOutlined, SettingOutlined, TeamOutlined, UnorderedListOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons'
import { ConfigProvider, Menu as AntdMenu } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useContext } from 'react'
import menus, { sideMenuItensIconsStyle } from './options/Menus'
import { Link, useLocation } from 'react-router-dom'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/styles'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

export function Menu({ collapsed, isSideMenu = true }) {
    const [menu_selected_keys, setMenuSelectedKeys] = useState([])
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(true)
    const { setLoggedUser, isAdmin } = useContext(AuthContext)

    const location = useLocation()

    const menuOptions = useMemo(() => [...(isAdmin ? menus.adminSideMenuItens : []), ...menus.userSideMenuItens, ...menus.topMenuItens], [isAdmin])

    useEffect(() => {
        if (firstTimeLoaded) {
            selectMenuOnLoading()
            setFirstTimeLoaded(false)
        }
    }, [location])

    //Seleciona o item do menu correspondente com a rota
    const selectMenuOnLoading = () => {
        let pathOption = window.location.pathname
        // if (pathOption == '/') {
            //     setMenuSelectedKeys([menus.userSideMenuItens[0].key])
            //     return
        // }

        const index = pathOption.indexOf('/', 1)

        if (index > 0) {
            pathOption = pathOption.substring(0, index)
        }

        const foundCurrentOption = menuOptions.find(e => e.key == pathOption)

        if (foundCurrentOption) {
            setMenuSelectedKeys([foundCurrentOption.key])
        }
    }

    const handleOnClickLogout = () => {
        setLoggedUser(null)
    }

    const handleOnClickItemMenu = e => {
        setMenuSelectedKeys([e.key])
    }

   

    const listSideMenuItens = useMemo(
        () => [
            {
                key: '/profile',
                icon: <UserOutlined style={{ ...sideMenuItensIconsStyle, color: 'white' }} />,
                label: (
                    <Link className="text-white text-base" to={'/profile'}>
                        <span>{displayMessage('MY_PROFILE')}</span>
                    </Link>
                ),
            },
            {
                key: '/reports',
                icon: <UnorderedListOutlined style={{ ...sideMenuItensIconsStyle, color: 'white' }} />,
                label: (
                    <Link
                        className='text-white text-base'
                        to={'/reports'}
                    >
                        <span>{displayMessage('REPORTS')}</span>
                    
                    </Link>
                ),
            },
            {
                key: '/followups',
                icon: <FileSearchOutlined style={{ ...sideMenuItensIconsStyle, color: 'white' }} />,
                label: (
                    <Link
                        className='text-white text-base'
                        to={'/followups'}
                    >
                        <span>{displayMessage('FOLLOW_UPS')}</span>
                    
                    </Link>
                ),
            },
            {
                key: '/login',
                icon: <LoginOutlined style={{ ...sideMenuItensIconsStyle, color: `white` }} />,
                label: (
                    <Link className="text-white text-base" to={'/login'} onClick={handleOnClickLogout}>
                        <span>{displayMessage('LOGOUT')}</span>
                    </Link>
                ),
            },
        ],
        [menuOptions]
    )

    return (
        <ConfigProvider
            theme={{
                token: {
                    controlItemBgActiveHover: colors.primary[700],
                    colorBgTextHover: colors.primary[800],
                },
            }}
        >
            <AntdMenu
                mode="inline"
                defaultOpenKeys={['menu']}
                className={`flex-1 border-r-0 bg-primary-900 pt-5 h-full ${isSideMenu ? 'rounded-none' : 'rounded-sm'}`}
                selectedKeys={menu_selected_keys}
                inlineCollapsed={collapsed}
                onSelect={handleOnClickItemMenu}
                items={listSideMenuItens}
            />
        </ConfigProvider>
    )
}
