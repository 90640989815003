const { displayMessage } = require("../helpers/messages");

module.exports = {
    INFORMED: { code: 1, description: displayMessage('INFORMED') },
    JUSTIFIED: { code: 2, description: displayMessage('JUSTIFIED') },
    SERVED: { code: 3, description: displayMessage('SERVED') },
    PARTIALLY_SERVED: { code: 4, description: displayMessage('PARTIALLY_SERVED') },
    IN_PROGRESS: { code: 5, description: displayMessage('IN_PROGRESS') },
    ECONOMIC_VIABILITY_STUDY: { code: 6, description: displayMessage('ECONOMIC_VIABILITY_STUDY') },
    TECHNICAL_VIABILITY_STUDY: { code: 7, description: displayMessage('TECHNICAL_VIABILITY_STUDY') },
    NOT_SERVED: { code: 8, description: displayMessage('NOT_SERVED') }
}