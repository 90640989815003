import { useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import api from "../../../services/api"
import { SL_ClipLoader } from "../../../components/loading/ClipLoader"
import { Card, Checkbox, Collapse, List, Tabs, Tooltip } from "antd"
import { displayMessage } from "../../../helpers/messages"
import { SL_Button } from "../../../components/buttons/Button"
import { formatCnae, formatCnpj } from "../../../helpers/helper"
import { SL_Grid } from "../../../components/grid/SL_Grid"
import CustomToolbar from "../../../components/grid/CustomToolbar"
import { GeneralContext } from "../../../context/GeneralContext/GeneralContext"
import { report_risk_grid_columns } from "../grid_columns/report_risk_grid_columns"
import { ModalReportRiskDetails } from "./ModalReportRiskDetails"
import { InfoCircleOutlined } from "@ant-design/icons"
import { FollowUps } from "./FollowUps/FollowUps"

const { Panel } = Collapse
const TAB_REPORT_DETAIL_KEY = 50
const TAB_FOLLOW_UPS_LIST_KEY = 51

const GENERAL_DATA_KEY = 1
const PARTICIPANTS_KEY = 2
const RISKS_IDENTIFICATION_KEY = 3
const STRENGTHS_KEY = 4

export function ReportDetails({ }) {
    let eRiskTypes = null

    const { reportId } = useParams()

    const { mobile_mode } = useContext(GeneralContext)

    const [primaryCollapseActiveKey, setPrimaryCollapseActiveKey] = useState(null)
    const [loading, setLoading] = useState(false)
    const [gridLoading, setGridLoading] = useState(false)
    const [report, setReport] = useState(null)
    const [selectedReportConfig, setSelectedReportConfig] = useState(null)
    const [reportRisks, setReportRisks] = useState([])
    const [strengths, setStrengths] = useState([])
    const [followUps, setFollowUps] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [selectedReportUsers, setSelectedReportUsers] = useState([])
    const [selectedStrengths, setSelectedStrengths] = useState([])
    const [rowOnFocus, setRowOnFocus] = useState(null)
    const [modalReportRiskDetailsIsOpen, setModalReportRiskDetailsIsOpen] = useState(false)
    let [searchParams, setSearchParams] = useSearchParams()
    let is_followups = searchParams.get('followups') 
    const [activeKey, setActiveKey] = useState(TAB_REPORT_DETAIL_KEY)

    useEffect(() => {
        loadReport()
        loadStrengths(),
        handleOnTabChange(TAB_REPORT_DETAIL_KEY)
    }, [])

    useEffect(() => {
        if (is_followups) {
            setActiveKey(TAB_FOLLOW_UPS_LIST_KEY)
        }
    }, [searchParams])

    const loadReport = async (id = reportId) => {
        setLoading(true)
        setGridLoading(true)
        try {
            if (!eRiskTypes) {
                const enumRes = await api.enums.find('ERiskTypes')
                eRiskTypes = enumRes.data              
            }

            const { data } = await api.reports.find(id)
            setSelectedAddress(data.address_id)
            setSelectedReportUsers(data.ReportUsers)
            setSelectedStrengths(data.Strengths)
            setReportRisks(data.ReportRisks)
            setSelectedReportConfig(data.ReportConfig)
            setReport(data)
            createReportRiskSequence(data.ReportRisks)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            setGridLoading(false)
        }
    }

    const loadStrengths = async () => {
        try {
            const { data } = await api.strengths.list()

            setStrengths(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePrimaryCollapseChange = key => {
        setPrimaryCollapseActiveKey(key)
    }

    const loadFollowUps = async () => {
        setGridLoading(true)
        try {
            const { data } = await api.followUps.list({ report_id: reportId })

            setFollowUps(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const tabKeysWithYourListFunction = [
        { key_code: TAB_REPORT_DETAIL_KEY, list_function: loadReport },
        { key_code: TAB_FOLLOW_UPS_LIST_KEY, list_function: loadFollowUps },
    ]

    const handleOnTabChange = async key => {
        setActiveKey(key)
        let listFunctionToCall = null
        tabKeysWithYourListFunction.forEach(e => {
            if (key === e.key_code) listFunctionToCall = e.list_function
            return
        })

        if (listFunctionToCall) {
            await listFunctionToCall()
        }
    }

    const handleOnDownload = async (attachment) => {
        try {
            const { data } = await api.attachments.openAttachment(attachment.id, { responseType: 'blob' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${attachment?.name}`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getSequenceDescription = async (report_risk) => {
        const { data } = await api.reportRisks.find(report_risk.id)
        const risk_type = data?.Risk?.RiskKind?.RiskActivity?.RiskType
        const risk_type_acronym = Object.values(eRiskTypes).find(e => risk_type?.code == e.code)?.acronym
        return risk_type_acronym
    } 

    const createReportRiskSequence = async (reportRisks) => {
        let sequence, max_one_digit = 9, max_two_digits = 99
        const report_risks_formatted = []
        for (let i = 0; i < reportRisks.length; i++) {
            const report_risk = reportRisks[i]
            if (report_risk.is_from_app) {
                continue
            }
            const risk_type_acronym = await getSequenceDescription(report_risk)
            if (i < max_one_digit) {
                sequence = `00${i + 1}`
            } else if (i < max_two_digits) {
                sequence = `0${i + 1}`
            } else {
                sequence = `${i + 1}`
            }
            report_risks_formatted.push({...report_risk, sequence: `${risk_type_acronym}${sequence}`})
        }
        setReportRisks(report_risks_formatted)
        if(rowOnFocus) setRowOnFocus(reportRisks.find(rr => rr.id == rowOnFocus.id))

        return report_risks_formatted ?? [];

    };


    return <>
        <Tabs
            activeKey={activeKey}
            onChange={handleOnTabChange}
            items={[
                {
                    key: TAB_REPORT_DETAIL_KEY,
                    label: displayMessage('REPORT_DETAILS'),
                    children: <>
                        <div>
                            {
                                loading
                                    ? <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                                        <SL_ClipLoader loading={true} />
                                    </div>
                                    : <>
                                        <div className="w-full flex justify-center">
                                            <div className="mr-2 font-bold text-lg">
                                                {displayMessage('REPORT_EDITION')}
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-col">
                                            <Collapse
                                                activeKey={primaryCollapseActiveKey}
                                                onChange={handlePrimaryCollapseChange}
                                                ghost
                                                accordion
                                                size="large"
                                                className="w-full"
                                            >
                                                <Panel header={displayMessage('GENERAL_DATA')} key={GENERAL_DATA_KEY}>      
                                                <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5`}>
                                                    <Card
                                                        title={'Informações da empresa'}
                                                        className="w-full bg-sl-gray-50"
                                                    >
                                                        <div>
                                                            {displayMessage('CLIENT_NAME')}: {report?.Address?.Client?.name}
                                                        </div>
                                                        <div>
                                                            {displayMessage('CLIENT_CNPJ')}: {report?.Address?.Client?.cnpj && formatCnpj(report?.Address?.Client?.cnpj)}
                                                        </div>
                                                        <div>
                                                            {displayMessage('CLIENT_NUMBER_CNAE')}: {report?.Address?.Client?.cnae_number && formatCnae(report?.Address?.Client?.cnae_number)}
                                                        </div>
                                                        <div>
                                                            {displayMessage('CLIENT_CNAE_DESCRIPTION')}: {report?.Address?.Client?.cnae}
                                                        </div>
                                                    </Card>
                                                    <Card
                                                        title={'Endereços da empresa'}
                                                        className="w-full bg-sl-gray-50"
                                                        >
                                                        {
                                                            report?.Address?.Client?.Addresses.map((item, index) => {
                                                                const address_selected = selectedAddress == item.id
                                                                return (
                                                                <div className={`flex flex-row w-full`}>
                                                                    <div className={`flex flex-wrap flex-row ${item.already_used == true ? 'underline' : ''}`}>
                                                                        <div className="mr-2">
                                                                            {item.state} -
                                                                        </div>
                                                                        <div className="mr-2">
                                                                            {item.city} -
                                                                        </div>
                                                                        <div className="mr-2">
                                                                            {item.neighbourhood} -
                                                                        </div>
                                                                        <div className="mr-2">
                                                                            {item.street} -
                                                                        </div>
                                                                        <div>
                                                                            {item.number}
                                                                        </div>
                                                                        <Checkbox
                                                                            className="ml-5"
                                                                            disabled={item.already_used == true || report?.finalization_date}
                                                                            checked={address_selected}
                                                                        />
                                                                        {
                                                                                address_selected && <p className="ml-3 font-bold">{displayMessage('SELECTED_ADDRESS').toUpperCase()}</p>
                                                                        }
                                                                        {
                                                                            item.already_used == true && !address_selected &&
                                                                            <div className="ml-5">
                                                                                <Tooltip placement="top" title={displayMessage('ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT')}>
                                                                                    <InfoCircleOutlined style={{ fontSize: '20px' }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    </div>
                                                                )})
                                                            }
                                                    </Card>
                                                </div>
                                                </Panel>
                                                <Panel header={displayMessage('PARTICIPANTS')} key={PARTICIPANTS_KEY}>
                                                    {
                                                        selectedReportUsers.length > 0
                                                            ? <List
                                                                size="large"
                                                                bordered
                                                                dataSource={selectedReportUsers}
                                                                renderItem={item =>
                                                                    <List.Item>
                                                                        <div className="w-full flex flex-row mt-1">
                                                                            <div>
                                                                                {item?.name} | {item?.work_description}
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                }
                                                            />
                                                            : <p className="p-1">{displayMessage('NO_PARTICIPANT_SELECTED')}</p>
                                                    }
                                                </Panel>
                                                <Panel header={displayMessage('RISKS_IDENTIFICATION')} className="w-full" key={RISKS_IDENTIFICATION_KEY}>
                                                    <div className="flex w-full">
                                                        {
                                                            modalReportRiskDetailsIsOpen &&
                                                            <ModalReportRiskDetails
                                                                isOpen={modalReportRiskDetailsIsOpen}
                                                                onClose={setModalReportRiskDetailsIsOpen}
                                                                reportRisk={rowOnFocus}
                                                                reloadReportRisks={loadReport}
                                                                isReportFinished={report?.finalization_date}
                                                            />
                                                        }

                                                        <SL_Grid
                                                            rows={reportRisks}
                                                            sortingMode={'server'}
                                                            components={{
                                                                Toolbar: () => (
                                                                    <>
                                                                        <div className="flex justify-between flex-row ss:flex-row">
                                                                            <CustomToolbar />
                                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                                <SL_Button
                                                                                    className="my-1 mr-1"
                                                                                    disabled={!rowOnFocus}
                                                                                    onClick={() => setModalReportRiskDetailsIsOpen(true)}
                                                                                >
                                                                                    {displayMessage('RISK_DETAILS')}
                                                                                </SL_Button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ),
                                                            }}
                                                            disableFilter={false}
                                                            onRowClick={params => {
                                                                setRowOnFocus(params.row)
                                                            }}
                                                            columns={report_risk_grid_columns(handleOnDownload)}
                                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                            loading={gridLoading}
                                                        />
                                                    </div>
                                                </Panel>
                                                <Panel header={displayMessage('STRENGHTS')} key={STRENGTHS_KEY}>
                                                    <div className="flex flex-col">
                                                        {
                                                            selectedStrengths.length > 0
                                                                ? <List
                                                                    size="large"
                                                                    bordered
                                                                    dataSource={strengths.filter(e => (selectedStrengths.map(e => e.id)).includes(e.id))}
                                                                    renderItem={item => {
                                                                        return <>
                                                                            <List.Item>
                                                                                <div className="w-full flex flex-row">
                                                                                    <div>
                                                                                        {item?.title}
                                                                                    </div>
                                                                                </div>
                                                                            </List.Item>
                                                                        </>
                                                                    }}
                                                                />
                                                                : <p className="p-1">{displayMessage('NO_STRENGHT_SELECTED')}</p>
                                                        }
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </>
                            }
                        </div>
                    </>
                },
                {
                    key: TAB_FOLLOW_UPS_LIST_KEY,
                    label: displayMessage('FOLLOW_UPS'),
                    children: <FollowUps 
                        loadFollowUps={loadFollowUps}
                        followUps={followUps}
                        gridLoading={gridLoading}
                    />
                }
            ]}
        />
    </>

}