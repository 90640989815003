import { useContext, useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Form } from 'antd'

import { SL_Input } from '../../components/input/Input'
import { SL_Button } from '../../components/buttons/Button'

import { EAlertTypes } from '../../enums/EAlertTypes'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { AuthContext } from '../../context/AuthContext/AuthContext'

import api from '../../services/api'
import styles from '../../services/styles'

export function Profile({}) {
    const [form] = Form.useForm()
    const { showAlert, setShowLoading, mobile_mode } = useContext(GeneralContext)
    const { loggedUser, setLoggedUser } = useContext(AuthContext)

    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmedNewPassword, setConfirmedNewPassword] = useState(null)
    const [isValidPassword, setIsValidPassword] = useState(true)

    const [name, setName] = useState(null)
    const [lastName, setLastName] = useState(null)


    const validatePassword = value => {
        const lowercaseRegex = /[a-z]/
        const uppercaseRegex = /[A-Z]/
        const numberRegex = /[0-9]/
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/

        const isLowercaseValid = lowercaseRegex.test(value)
        const isUppercaseValid = uppercaseRegex.test(value)
        const isNumberValid = numberRegex.test(value)
        const isSpecialCharValid = specialCharRegex.test(value)

        const validPassword =
            isLowercaseValid && isUppercaseValid && isNumberValid && isSpecialCharValid

        setIsValidPassword(validPassword)
    }

    const inputStyle = {
        border: `solid 1px ${styles.colors.primary['900']}`,
        height: 40,
        width: mobile_mode ? '100%' : 'auto',
    }

    const handleChangePassword = async () => {
        setShowLoading(true)
        try {
                        if (newPassword != confirmedNewPassword) {
                showAlert('PASSWORD_CONFIRMATION_FAILED', EAlertTypes.ERROR)
                throw new Error()
            }
            if (!newPassword || !confirmedNewPassword || !oldPassword) {
                showAlert('FILL_ALL_FIELDS_CHANGE_PASSWORD', EAlertTypes.ERROR)
                throw new Error()
            }

            await api.authClient.changePassword(oldPassword, newPassword)

            resetFieldsUpdatePassword()
            showAlert('PASSWORD_CHANGE_SUCCESS', EAlertTypes.SUCCESS)
            setLoggedUser(false)
        } catch (error) {
            console.log(error)
            resetFieldsUpdatePassword()
        } finally {
            setShowLoading(false)
        }
    }

    const handleChangeUserData = async user_data => {
        setShowLoading(true)
        try {
            const { data } = await api.user.update(loggedUser.id, user_data)
            setLoggedUser(data)
            // form.setFieldsValue({ cpf: '' })
            showAlert('EDIT_USER_DATA_SUCCESS', EAlertTypes.SUCCESS)
        } catch (error) {
            console.log(error)
            initUserData(loggedUser)
        } finally {
            setShowLoading(false)
        }
    }

    const resetFieldsUpdatePassword = () => {
        setOldPassword('')
        setNewPassword('')
        setConfirmedNewPassword('')
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-1 flex-row">
                <div
                    style={{
                        color: 'white',
                        background: styles.colors.primary['900'],
                        padding: 20,
                    }}
                >
                    <UserOutlined style={{ fontSize: 80 }} />
                </div>
                <div className="flex flex-1 flex-col justify-end ml-3 text-base font-bold">
                    <div className="text-primary-900">
                        {loggedUser?.first_name} {loggedUser?.last_name}
                    </div>
                    <div style={{ color: styles.colors.primary['900'] }}>{loggedUser?.email}</div>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: mobile_mode ? 'column' : 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 30,
                    color: styles.colors.primary['900'],
                    width: '100%',
                    borderTop: '1px solid lightgray',
                }}
            >
                <div className="pb-25 flex flex-col w-full">
                    <div className="mt-5 text-center font-bold text-lg">
                        {displayMessage('CHANGE_PASSWORD')}
                    </div>
                    <div className="flex flex-col mt-10">
                        <div
                            style={{
                                display: 'flex',
                                gap: 15,
                                alignItems: 'center',
                                flexDirection: mobile_mode ? 'column' : 'row',
                            }}
                        >
                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div style={{ fontWeight: 'bold' }}>
                                    {displayMessage('OLD_PASSWORD')}:
                                </div>
                                <SL_Input
                                    type="password"
                                    value={oldPassword}
                                    onChange={e => {
                                        setOldPassword(e.target.value)
                                    }}
                                    style={{
                                        border: `solid 1px ${styles.colors.primary['900']}`,
                                        height: 40,
                                        width: mobile_mode ? '100%' : 'auto',
                                    }}
                                />
                            </div>

                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div className="font-bold text-sm">
                                    {displayMessage('NEW_PASSWORD')}:
                                </div>
                                <SL_Input
                                    type="password"
                                    value={newPassword}
                                    onChange={e => {
                                        setNewPassword(e.target.value)
                                        validatePassword(e.target.value)
                                    }}
                                    style={{
                                        border: `solid 1px ${styles.colors.primary['900']}`,
                                        height: 40,
                                        width: mobile_mode ? '100%' : 'auto',
                                    }}
                                />
                            </div>

                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div className="font-bold text-sm">
                                    {displayMessage('CONFIRM_NEW_PASSWORD')}:
                                </div>
                                <SL_Input
                                    type="password"
                                    value={confirmedNewPassword}
                                    onChange={e => {
                                        setConfirmedNewPassword(e.target.value)
                                        validatePassword(e.target.value)
                                    }}
                                    style={{
                                        border: `solid 1px ${styles.colors.primary[900]}`,
                                        height: 40,
                                        width: mobile_mode ? '100%' : 'auto',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col text-red-600 text-sm">
                            {!isValidPassword && displayMessage('PASSWORD_VALIDATION')}
                        </div>
                    </div>
                    <div className="flex w-full justify-end gap-3">
                        <SL_Button
                            onClick={handleChangePassword}
                            style={{ width: mobile_mode ? '100%' : '150px', marginTop: 15 }}
                        >
                            {displayMessage('CONFIRM_CHANGE')}
                        </SL_Button>
                    </div>

                    <div className="mt-5 text-center font-bold text-lg">
                        {displayMessage('CHANGE_USER_DATA')}
                    </div>

                    <Form
                        onFinish={handleChangeUserData}
                        className="flex flex-wrap w-full gap-3"
                        layout="vertical"
                        requiredMark={false}
                        form={form}
                    >
                        <div className="flex flex-col">
                            <div className="font-bold text-sm">{displayMessage('FIRST_NAME')}</div>

                            <Form.Item
                                name="first_name"
                                rules={[
                                    { required: true, message: displayMessage('DISPLAY_NAME'), 
                                    validator: (_, value) => {
                                        if (value.length < 3 || !value.trim()) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    }
                                },
                                ]}
                            >
                                <SL_Input
                                    placeholder={displayMessage('FIRST_NAME')}
                                    style={inputStyle}
                                    className="w-[300px]"
                                />
                            </Form.Item>
                        </div>

                        <div className="flex flex-col">
                            <div className="font-bold text-sm">{displayMessage('LAST_NAME')}</div>

                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: displayMessage('DISPLAY_LAST_NAME'),
                                        validator: (_, value) => {
                                            if (value.length < 3 || !value.trim()) {
                                                return Promise.reject();
                                            }
                                            return Promise.resolve();
                                        }
                                    },
                                ]}
                            >
                                <SL_Input
                                    placeholder={displayMessage('LAST_NAME')}
                                    style={inputStyle}
                                    className="w-[300px]"
                                />
                            </Form.Item>
                        </div>

                        <div className="flex flex-col">
                            <div className="font-bold text-sm">{displayMessage('EMAIL')}</div>

                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: displayMessage('DISPLAY_EMAIL'), type: 'email' },
                                ]}
                            >
                                <SL_Input
                                    placeholder={displayMessage('EMAIL')}
                                    style={inputStyle}
                                    className="w-[300px]"
                                />
                            </Form.Item>
                        </div>

                        <div className="flex w-full justify-end gap-3">
                            <SL_Button onClick={() => form.submit()}>
                                {displayMessage('CONFIRM_DATA_EDITION')}
                            </SL_Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
