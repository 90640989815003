import { DataGridPro } from '@mui/x-data-grid-pro'
import CustomToolbar from './CustomToolbar'

const defaultPageSize = 40

export function SL_Grid({ rows, columns, onRowClick, ...rest }) {
    return (
        <DataGridPro
            rows={rows}
            columns={columns}
            onRowClick={onRowClick}
            pagination
            pageSize={defaultPageSize}
            rowsPerPageOptions={[defaultPageSize]}
            components={{
                Toolbar: () => <CustomToolbar />,
                Header: () => <></>,
            }}
            density="compact"
            disableMultipleSelection
            {...rest}
        />
    )
}
