import { useContext, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import {
    PaperPlaneRight,
    Paperclip,
    XCircle,
} from '@phosphor-icons/react'
import { SL_Input } from '../../../../../components/input/Input'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../../../helpers/messages'
import { colors } from '../../../../../services/styles'
import api from '../../../../../services/api'
import { ModalAttachmentChoice } from './ModalAttachmentChoice'
import { ChatMessage } from './ChatMessage'
import { EAlertTypes } from '../../../../../enums/EAlertTypes'
import { AuthContext } from '../../../../../context/AuthContext/AuthContext'
import './chat.css'

const max_recommendation_characters = 75

export function Chat({ isFollowupFinished, followupReportRiskId, recommendation }) {
    const { showAlert } = useContext(GeneralContext)
    const { loggedUser } = useContext(AuthContext)

    const [messages, setMessages] = useState([])

    const [text, setText] = useState(null)

    const [isAttachmentModalOpen, setIsModalAttachmentOpen] = useState(false)
    const [attachmentsToSend, setAttachmentsToSend] = useState([])
    const [followupReportRisk, setFollowupReportRisk] = useState(null)

    useEffect(() => {
        loadConversation()
        loadFollowupReportRisk()
    }, [])

    useEffect(() => {
        if (!isAttachmentModalOpen) {
          document.getElementById('inputMessage').focus();
        }
      }, [isAttachmentModalOpen]);

    const loadFollowupReportRisk = async () => {
        try {
            const { data } = await api.followupReportRisk.find(followupReportRiskId)
            setFollowupReportRisk(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadConversation = async () => {
        try {
            const { data } = await api.followupApprovals.list({ followup_report_risk_id: followupReportRiskId, order_by_date_asc: true })
            setMessages(data)
            scrollToEnd()
        } catch (error) {
            console.log(error)
        } 
    }

    const sendTextMessage = async () => {
        try {
            const values = {
                description: text,
                followup_report_risk_id: followupReportRiskId,
                client_id: loggedUser.id
            }
            await sendMessage(values)
            setText(null)
        } catch (error) {
            console.log(error)
        } 
    }

    const validateBeforeSendMessage = () => {
        if (attachmentsToSend.length > 0 && !text) {
            showAlert('ERROR_MISSING_DESCRIPTION', EAlertTypes.ERROR)
            return false
        }

        if (!text) {
            showAlert('ERROR_MISSING_DESCRIPTION', EAlertTypes.ERROR)
            return false
        }

        return true
    } 

    const sendMessage = async (values, needDowload) => {
        try {
            const body = new FormData()
            body.append('data', JSON.stringify(values))

            
            if(attachmentsToSend) {
                for (let i = 0; i < attachmentsToSend.length; i++) {
                    const file = attachmentsToSend[i]
                    body.append('attachments', file)
                }
            } 
            const is_valid = validateBeforeSendMessage()
            if (is_valid) {
                const { data } = await api.followupApprovals.sendMessage(body)
                if(needDowload) {
                    const response = await api.attachments.download(data.attachment_id)
                    data.content = response.data
                }
                setMessages(current => [...current, data])
                await loadConversation()
                setAttachmentsToSend([])
            }
        } catch (error) {
            console.log(error)
        } 
    }

    const scrollToEnd = () => {
        const chatElement = document.getElementById('chat')
        if (chatElement) {
            chatElement.scrollTop = chatElement.scrollHeight
        }
    }

    const handleOnRemoveAttachments = attachment => {
        const attachments_filtered = attachmentsToSend.filter(attachment_file => attachment_file.name != attachment.name)
        if (attachments_filtered.length == 0) {
            setAttachmentsToSend([])   
        } else {
            setAttachmentsToSend(attachments_filtered)
        }
    }

    return (
        <div
            className={`h-full flex flex-col w-full rounded-md bg-primary-100`}
            style={{
                border: `2px solid ${colors.primary[700]}`,
            }}
        >

            {isAttachmentModalOpen && (
                <ModalAttachmentChoice
                    onChangeAttachments={attachments => setAttachmentsToSend(attachments)}
                    onClose={() => setIsModalAttachmentOpen(false)}
                />
            )}

            <div className="flex flex-wrap gap-3 items-center rounded-md p-3 w-full justify-between bg-primary-700">
                {recommendation && (
                    <div className='mr-10'>
                        <Tooltip
                            placement='bottom'
                            overlayStyle={{ maxWidth: 600 }}
                            overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                            title={recommendation}
                            className="uppercase font-bold text-white w-full"
                        >
                            {
                                recommendation.length > max_recommendation_characters
                                ? `${recommendation.slice(0, max_recommendation_characters)}...`
                                : recommendation}
                        </Tooltip>
                </div>
                )}

            </div>
            <div
                className={`flex p-4 flex-col h-full overflow-auto modal-chat custom-scrolbar-chat gap-3 bg-primary-100 ${messages.length == 0 && 'justify-end items-end'}`}
                id="chat"
            >
                {messages.map(message =>
                (
                    <ChatMessage
                        key={message.id}
                        message={message}
                        followupReportRisk={followupReportRisk}
                    />
                ))}

            </div>

            {
                attachmentsToSend.length > 0 && !isAttachmentModalOpen &&
                <div className="bg-primary-200 rounded p-2 m-5 flex flex-col w-[97%] min-h-[15%] max-h-[40%] overflow-auto custom-scrolbar-chat">
                    <span className="font-bold text-xs">
                        {displayMessage('SELECTED_ATTACHMENTS')}
                    </span>
                    {
                        attachmentsToSend.map(attachment => {
                            return (
                                <div className='flex justify-between'>
                                    <span>{attachment.name}</span>
                                    <div className="flex justify-between">
                                        <XCircle
                                            className='cursor-pointer'
                                            size={16}
                                            color={colors.primary[900]}
                                            onClick={() => handleOnRemoveAttachments(attachment)}
                                        />
                                    </div>
                                </div>
                            )    
                        })        
                    }
                </div>
            }

            <div className="flex flex-row gap-3 items-center p-2 w-full  justify-between bg-primary-700 px-5"
                onKeyDown={e => {
                    e.key == 'Enter' && !isFollowupFinished && sendTextMessage()
                }}>
                <Tooltip placement="top" title={!isFollowupFinished && displayMessage('SAVE')}>
                    <Paperclip
                        aria-disabled={isFollowupFinished}
                        size={32}
                        color="white"
                        className={`${isFollowupFinished ? 'disabled bg-opacity-50' : 'cursor-pointer'}`}
                        onClick={() => !isFollowupFinished && setIsModalAttachmentOpen(true)}
                    />
                </Tooltip>
                
                <SL_Input
                    id='inputMessage'
                    disabled={isFollowupFinished}
                    placeholder={displayMessage('WRITE_A_MESSAGE')}
                    className={`w-[90%] bg-[#F1F3F4] px-2 rounded-full ${isFollowupFinished && 'disabled'}`}
                    maxLength={null}
                    value={text}
                    onChange={e => setText(e.target.value)}
                >
                </SL_Input>

                <Tooltip placement="top" title={!isFollowupFinished && displayMessage('SEND_MESSAGE')}>
                    <div
                        className={`bg-white h-[30px] w-[30px] flex justify-center items-center rounded-full ${isFollowupFinished ? 'disabled bg-opacity-50' : 'cursor-pointer'}`}
                        onClick={() => !isFollowupFinished && sendTextMessage()}
                    >
                        <PaperPlaneRight size={20} color={colors.primary[700]} weight="fill" />
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}
