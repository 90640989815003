import { ConfigProvider, Select } from 'antd'
import { twMerge } from 'tailwind-merge'
import styles from '../../services/styles'
import './Select.css'

const { colors } = styles

export function SL_Select({ className, ...rest }) {
    const classes = twMerge(`
        sl-select

        ${className}
    `)

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: colors.primary[900],
                },
            }}
        >
            <Select {...rest} className={classes} />
        </ConfigProvider>
    )
}
