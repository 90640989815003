module.exports = {
    aspectRatio: {
        equal: '1 / 1',
    },
    screens: {
        ss: '500px', // => @media (min-width: 500px) { ... }
        smb: '700px', // => @media (min-width: 700px) { ... }
    },
    width: {
        unset: 'unset',
    },
    height: {
        unset: 'unset',
    },
    colors: {
        //Hierarquia das cores
        primary: {
            100: '#d3e4e5',
            200: '#a7c9cb',
            300: '#7aaeaf',
            400: '#4e9395',
            500: '#24787b',
            600: '#1e6164',
            700: '#174a4d',
            800: '#113338',
            900: '#092121',
        },
        secondary: {
            100: '#bfe2e2',
            200: '#80cccc',
            300: '#4db8b8',
            400: '#26a3a3',
            500: '#027878',
            600: '#016565',
            700: '#005252',
            800: '#003e3e',
            900: '#002929',
        },

        dark: '#4d4d4f',

        light: '#f4eade',

        //Cores Gerais
        'sl-red': {
            100: '#faebeb',
            200: '#efc2c2',
            300: '#e59999',
            400: '#db7171',
            500: '#d04848',
            600: '#d04848',
            700: '#8e2424',
            800: '#661a1a',
            900: '#3d1010',
        },
        'sl-gray': {
            50: '#fafafa',
            100: '#f4f4f5',
            200: '#e4e4e7',
            300: '#d4d4d8',
            400: '#a1a1aa',
            500: '#71717a',
            600: '#52525b',
            700: '#3f3f46',
            800: '#27272a',
            900: '#18181b',
        },
    },
    //shadow
    boxShadow: {},
}
