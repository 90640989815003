import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { InternalLayout } from './components/layouts/InternalLayout'
import { ExternalLayout } from './components/layouts/ExternalLayout'
import { Login } from '../screens/login/Login'
import { GeneralRoute } from './components/GeneralRoute'
import { Breadcrumb } from '../components/breadcrumb/Breadcrumb'
import { Profile } from '../screens/profile/Profile'
import { ResetPasswordForm } from '../screens/resetPassword/ResetPasswordForm'
import { Reports } from '../screens/reports/Reports'
import { ReportDetails } from '../screens/reports/components/ReportDetails'
import { FollowUpDetails } from '../screens/reports/components/FollowUps/components/FollowUpDetails'
import { FollowUpsScreen } from '../screens/followUps/components/FollowUpsScreen'

const router = createBrowserRouter([
    {
        path: '/',
        element: <GeneralRoute />,
        children: [
            //Rotas privadas
            {
                path: '/',
                element: <PrivateRoute />,
                children: [
                    {
                        path: '/',
                        element: <InternalLayout />,
                        children: [
                            {
                                path: 'profile',
                                element: (
                                    <Breadcrumb>
                                        <Profile />
                                    </Breadcrumb>
                                ),
                                handle: { crumb: { title: 'MY_PROFILE' } }
                            },
                            {
                                path: 'reports',
                                handle: { crumb: { title: 'REPORTS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <Reports />
                                            </Breadcrumb>
                                        )
                                    },
                                    {
                                        path: ':reportId',
                                        handle: {
                                            crumb: {
                                                title: 'REPORT',
                                                params: ['reportId']
                                            }
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <ReportDetails />
                                                    </Breadcrumb>
                                                )
                                            },
                                            {
                                                path: 'follow-up/:followUpId',
                                                handle: {
                                                    crumb: {
                                                        title: 'FOLLOW_UP',
                                                        params: ['followUpId']
                                                    }
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <Breadcrumb>
                                                                <FollowUpDetails />
                                                            </Breadcrumb>
                                                        )
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'followups',
                                handle: { crumb: { title: 'FOLLOW_UPS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <FollowUpsScreen />
                                            </Breadcrumb>
                                        )
                                    },
                                    // {
                                    //     path: ':reportId',
                                    //     handle: {
                                    //         crumb: {
                                    //             title: 'REPORT',
                                    //             params: ['reportId']
                                    //         }
                                    //     },
                                    //     children: [
                                    //         {
                                    //             index: true,
                                    //             element: (
                                    //                 <Breadcrumb>
                                    //                     <ReportDetails />
                                    //                 </Breadcrumb>
                                    //             )
                                    //         },
                                    //         {
                                    //             path: 'follow-up/:followUpId',
                                    //             handle: {
                                    //                 crumb: {
                                    //                     title: 'FOLLOW_UP',
                                    //                     params: ['followUpId']
                                    //                 }
                                    //             },
                                    //             children: [
                                    //                 {
                                    //                     index: true,
                                    //                     element: (
                                    //                         <Breadcrumb>
                                    //                             <FollowUpDetails />
                                    //                         </Breadcrumb>
                                    //                     )
                                    //                 }
                                    //             ]
                                    //         }
                                    //     ]
                                    // },
                                ]
                            },
                        ]
                    },

                ],
            },

            //Rotas públicas
            {
                path: 'login',
                element: <ExternalLayout />,
                children: [
                    {
                        index: true,
                        element: <Login />,
                    },
                    {
                        path: 'reset-password/:token',
                        element: <ResetPasswordForm />,
                    },
                ],
            },
        ],
    },
])

export function Router() {
    return <RouterProvider router={router} />
}
