import { Tabs } from "antd"
import { displayMessage } from "../../helpers/messages"
import { useContext, useEffect, useState } from "react"
import api from "../../services/api"
import { SL_Grid } from "../../components/grid/SL_Grid"
import CustomToolbar from "../../components/grid/CustomToolbar"
import { SL_Button } from "../../components/buttons/Button"
import { GeneralContext } from "../../context/GeneralContext/GeneralContext"
import { reports_grid_columns } from "./grid_columns/reports_grid_columns"
import { useNavigate, useSearchParams } from "react-router-dom"
import { DownloadOutlined } from "@ant-design/icons"
import { AuthContext } from "../../context/AuthContext/AuthContext"
import { SL_Select } from "../../components/selects/Select"

const LIST_REPORTS_KEY = 1

export function Reports({ }) {

    const { mobile_mode } = useContext(GeneralContext)
    const { loggedUser } = useContext(AuthContext)
    
    const navigate = useNavigate()

    const [reports, setReports] = useState([])

    const [gridLoading, setGridLoading] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(null)
    const [selectedClientId, setSelectedClientId] = useState(null) 
    const [clients, setClients] = useState([])

    const [activeTab, setActiveTab] = useState(LIST_REPORTS_KEY)
    let [searchParams, setSearchParams] = useSearchParams()
    let report_id = searchParams.get('report_id') 

    useEffect(() => {
        loadReports({ report_id })
        loadClients()
    }, [selectedClientId])

    useEffect(() => {
        if (report_id) {
            loadReports({ report_id })
        }
    }, [searchParams])

    const loadReports = async params => {
        setGridLoading(true)
        try {
            params = {
                ...params,
                finished: true,
                type: loggedUser.type,
                userId: loggedUser.id,
                client_id: selectedClientId,
                by_client: true
            }
            const { data } = await api.reports.list(params)

            setReports(data)
        } catch (error) {
            console.error(error)
        } finally {
            setGridLoading(false)
        }
    }

    const handleTabChange = key => {
        setActiveTab(key)
    }

    const loadClients = async () => {
        try {
            const { data } = await api.clientUserAssociated.list({client_user_id: loggedUser.id})
            setClients(data)
        } catch (error) {
            console.log(error)
        }
    }

    const generatePdf = async(reportId) => {
        setGridLoading(true)
        try {
            const pdfName = await api.reports.generate_pdf({id: reportId, follow_up: false})
            const response = await api.reports.open_pdf(pdfName.data, {responseType: 'blob'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(response.data)
            link.download = `Relatório_${reportId + '_' + pdfName.data}.pdf`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }

            alert(PDF_DOWNLOADED_SUCCESS, 'success')
            console.log(reportId)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return <>
    {loggedUser.type == 1 && (
        <>
        <p className="my-2">{displayMessage('SELECT_ONE_CLIENT')}</p>
                <SL_Select
                    className="w-1/3 mb-6" 
                    options={[{ label: 'Todas', value: null }, ...clients.map((c) => ({ label: c.client.name, value: c.client.id }))]}
                    value={selectedClientId}
                    placeholder={displayMessage('SELECT_ONE_CLIENT')}
                    allowClear 
                    showSearch 
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    } 
            onChange={(value) => setSelectedClientId(value)}
            />
        </>
    )}
        <Tabs
            defaultActiveKey={LIST_REPORTS_KEY}
            activeKey={activeTab}
            onChange={handleTabChange}
            items={[
                {
                    key: LIST_REPORTS_KEY,
                    label: displayMessage('REPORTS'),
                    children: <>
                        <SL_Grid
                            rows={reports}
                            sortingMode={'server'}
                            components={{
                                Toolbar: () => (
                                    <>
                                        <div className="flex justify-between flex-row ss:flex-row">
                                            <CustomToolbar />
                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                <SL_Button
                                                    className="my-1 mr-1"
                                                    disabled={!rowOnFocus}
                                                    onClick={() => navigate(`/reports/${rowOnFocus.id}`)}
                                                >
                                                    {displayMessage('REPORT_DETAILS')}
                                                </SL_Button>
                                                <SL_Button
                                                    className="my-1 mr-1"
                                                    disabled={!rowOnFocus}
                                                    loading={gridLoading}
                                                    onClick={() => generatePdf(rowOnFocus.id)}
                                                >
                                                    <DownloadOutlined />
                                                    {displayMessage('PDF')}
                                                </SL_Button>
                                            </div>
                                        </div>
                                    </>
                                ),
                            }}
                            disableFilter={false}
                            onRowClick={params => {
                                console.log(params.row)
                                setRowOnFocus(params.row)
                            }}
                            columns={reports_grid_columns}
                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                            loading={gridLoading}
                        />
                    </>
                },
            ]}

        />
    </>
}