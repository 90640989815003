module.exports = {
    INFORMATION_TECHNOLOGY: { code: 1, description: 'Tecnologia da Informação' },
    HEALTH: { code: 2, description: 'Saúde' },
    EDUCATION: { code: 3, description: 'Educação' },
    FINANCE_AND_FINANCIAL_SERVICES: { code: 4, description: 'Finanças e Serviços Financeiros' },
    RETAIL_AND_COMMERCE: { code: 5, description: 'Varejo e Comércio' },
    MANUFACTURING: { code: 6, description: 'Manufatura' },
    FOOD_AND_BEVERAGES: { code: 7, description: 'Alimentos e Bebidas' },
    ENERGY_AND_NATURAL_RESOURCES: { code: 8, description: 'Energia e Recursos Naturais' },
    CONSTRUCTION_AND_REAL_ESTATE: { code: 9, description: 'Construção e Imobiliária' },
    ENTERTAINMENT_AND_MEDIA: { code: 10, description: 'Entretenimento e Mídia' },
    PROFESSIONAL_SERVICES: { code: 11, description: 'Serviços Profissionais' },
    TRANSPORTATION_AND_LOGISTICS: { code: 12, description: 'Transporte e Logística' },
}
