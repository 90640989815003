import React, { useState } from 'react'
import { SL_Button } from '../../../components/buttons/Button'
import logo from '../../../assets/logo.png'
import logoSolve from '../../../assets/logoSolve.png'
import Sider from 'antd/lib/layout/Sider'
import { Menu } from './Menu'

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { ESolveData } from '../../../enums/ESolveData'

export function SideMenu() {
    const [collapsed, setCollapsed] = useState(true)

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    function onOpenWindow(link) {
        window.open(link, '_blank')
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} width={300}>
            <div className="flex flex-col bg-white h-full">
                <div
                    className={`flex flex-row justify-evenly mb-0 transition-all duration-1000 border-[0px] ${
                        collapsed ? 'h-16' : 'h-20 border-r-[1px] border-solid border-primary-900'
                    }`}
                >
                    {!collapsed && <img height={'90%'} width={'80%'} className={`aspect-equal mt-2 ${collapsed ? 'p-0' : ''}`} src={logo} />}

                    <div className={`flex justify-center items-center transition-all duration-1000 ${collapsed ? 'border-0' : ''}`}>
                        <SL_Button
                            type="primary"
                            onClick={toggleCollapsed}
                            className={`bg-primary-900 justify-center rounded-r-2xl w-[54px] h-[41px]`}
                            icon={
                                collapsed ? (
                                    <MenuUnfoldOutlined className="items-center text-2xl flex" />
                                ) : (
                                    <MenuFoldOutlined className="items-center text-2xl flex" />
                                )
                            }
                        />
                    </div>
                </div>

                <Menu collapsed={collapsed} />

                {/* <div
                    className={`flex justify-around items-center mb-0 border-[0px] h-[${collapsed ? '35px' : '70px'}] border-r-[1px] border-solid border-primary-900`}
                >
                    <img
                        className={`cursor-pointer ${collapsed ? 'h-10' : 'h-16'}`}
                        src={logoSolve}
                        onClick={() => onOpenWindow(ESolveData.WEBSITE)}
                    />

                   
                </div> */}
            </div>
        </Sider>
    )
}
