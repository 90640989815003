import { BellOutlined, UserOutlined } from '@ant-design/icons'
import { Badge, Button, Dropdown } from 'antd'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { displayMessage } from '../../../helpers/messages'
import menus from './options/Menus'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

export function TopMenu({ onNotificationButtonClick, countNotifications }) {
    const { setLoggedUser, loggedUser } = useContext(AuthContext)

    const navigate = useNavigate()

    const handleOnClickLogout = () => {
        setLoggedUser(null)
    }

    function createTopMenuItem(item) {
        return {
            key: item.key,
            label: item.title,
            style: { width: '100%' },
            onClick: () => {
                navigate(item.key)
            },
        }
    }

    const items = [
        ...menus.topMenuItens.map(item => createTopMenuItem(item)),

        {
            key: '/logout',
            label: displayMessage('LOGOUT'),
            label: (
                <Link to={'/login'} onClick={handleOnClickLogout}>
                    <span>{displayMessage('LOGOUT')}</span>
                </Link>
            ),
            style: { width: '100%' },
        },
    ]

    return (
        <div className="flex items-center">
            <div className='mr-3'>
                {loggedUser?.first_name} {loggedUser?.last_name}
            </div>
            <Badge count={countNotifications} className='z-[1] mr-3 ml-3'>
                <Button
                    icon={<BellOutlined className="text-xl text-primary-900 flex items-center" />}
                    className="flex items-center justify-center min-h-[40px] text-base text-primary-900"
                    onClick={onNotificationButtonClick}
                />
            </Badge>
            <Dropdown menu={{ items }} placement="bottomLeft" size="large">
                <Button
                    icon={<UserOutlined className="text-xl text-primary-900 flex items-center" />}
                    className="flex items-center justify-center min-h-[40px] text-base text-primary-900"
                >
                    {loggedUser?.full_name}
                </Button>
            </Dropdown>
        </div>
    )
}
